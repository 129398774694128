import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Container, Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAtom } from "jotai";
import { formdataAtom } from "../../lib/atom";

const FinancialAppLogin = () => {
  const router = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [email, setEmail] = useState("");
  const [formdata, setFormdata] = useAtom(formdataAtom);

  const handleSubmit = async () => {
    if (!email) {
      setError("Please provide a valid email");
      return;
    }

    setLoading(true);

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/get-application/${email}`;

    try {
      const res = await axios.get(apiUrl);
      if (res.data.message !== "Start new application") {
        setFormdata({ ...formdata, email: email });
        router("/financial-aid-application/form");
      } else {
        setError("This email has not registered for the employment bootcamp");
      }
    } catch (error) {
      const msg =
        error?.response?.data?.message ||
        error.message ||
        "An unexpected error occured. Please try again";
      setError(msg);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setEmailValid(false);
    setError("");
  };

  return (
    <Container className="d-flex justify-content-center align-items-center vh-50">
      <div
        className="p-4 shadow-sm bg-white rounded"
        style={{ width: "100%", maxWidth: "600px" }}
      >
        <h4 className="text-start text-uppercase fw-bold">Check-In</h4>
        <Form>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <div className="position-relative">
              <Form.Control
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={handleEmailChange}
                style={{ borderColor: error ? "#dc3545" : "" }}
                required
              />
              {loading && (
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    zIndex: 2,
                  }}
                >
                  <Spinner animation="border" size="sm" />
                </div>
              )}
            </div>
            {error && (
              <div className="text-danger mt-1" style={{ fontSize: "0.875em" }}>
                {error}
              </div>
            )}
          </Form.Group>

          <div className="mt-3 d-flex justify-content-end">
            <button
              onClick={handleSubmit}
              // disabled={!emailValid}
              type="button"
              className="btn btn-primary"
              style={{
                backgroundColor: "#ECA239",
                color: "black",
                border: "1px solid #ECA239",
              }}
            >
              Proceed
            </button>
          </div>
        </Form>
      </div>
    </Container>
  );
};

export default FinancialAppLogin;
