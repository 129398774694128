import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";

const FinancialAidHome = () => {

  return (
    <>
      <div className="mt-5" id="applyContainer" style={{ color: "#212529" }}>
        <section id="applicationHeader" style={{ padding: "0px" }}>
          <div className="container">
            <div className="row mt-3 justify-content-center">
              <h2
                className="text-center text-uppercase fw-bolder"
                style={{ color: "#ECA239" }}
              >
                FINANCIAL AID APPLICATION
              </h2>
            </div>
          </div>
        </section>
        <section id="applicationMain" style={{ padding: "0px" }}>
          <div className="container">
            <div
              className="row mt-3 justify-content-center slide-row"
              id="sliderRow"
            >
              <Outlet />
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default FinancialAidHome;