import React, { useState } from "react";
import { useAtom } from "jotai";
import { formdataAtom } from "../../lib/atom";
import { useFormValidation } from "../../hooks/useFormValidation";
import SaveAndContinueWithBackButton from "../Utility/SaveAndContinueWithBackButton";
import { useUpdateFinancialAid } from "../../hooks/useUpdateFinancialAid";
import TextArea from "../Form/TextArea";

const FinancialAid = () => {
  const [formdata, setFormdata] = useAtom(formdataAtom);
  const [errors, setErrors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { validate } = useFormValidation("financialAid");
  const { updateFinancialAid } = useUpdateFinancialAid();

  // Handle form submission
  const handleSubmit = async () => {
    setIsLoading(true);
    setErrors([]); // Clear previous errors

    // Validate form data
    const validationErrors = await validate();
    if (validationErrors.length) {
      setErrors(validationErrors);
      setIsLoading(false);
      return;
    }

    // Try updating the financial aid data
    try {
      await updateFinancialAid();
    } catch (error) {
      setErrors(["An error occurred while submitting your application."]);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div className="col-md-8 slide-col" id="financialAid">
      <h4 className="text-start text-uppercase fw-bold" style={{ color: "black" }}>
        Financial Aid Application Form
      </h4>

      {/* Display validation errors if any */}
      {errors.length > 0 && (
        <div className="alert alert-danger">
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}

      <form>
        <div className="mb-4">
          <TextArea
            name="typeOfPhone"
            id="typeOfPhone"
            label="What type of phone do you use?"
            value={formdata.typeOfPhone || ""}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <TextArea
            name="haveLaptop"
            id="haveLaptop"
            label="Do you have a laptop? (Yes or No)"
            value={formdata.haveLaptop || ""}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <TextArea
            name="parentAlive"
            id="parentAlive"
            label="Are your parents still alive? If yes, what do they do?"
            value={formdata.parentAlive || ""}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <TextArea
            name="employed"
            id="employed"
            label="Are you employed or unemployed? If unemployed, for how long?"
            value={formdata.employed || ""}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <TextArea
            name="affordableFee"
            id="affordableFee"
            label="How much can you afford to pay? (Your chances are higher if you make an offer that the selection team can consider)"
            value={formdata.affordableFee || ""}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <TextArea
            name="whyBootcamp"
            id="whyBootcamp"
            label="Why do you need to attend this bootcamp?"
            value={formdata.whyBootcamp || ""}
            onChange={handleChange}
          />
        </div>
        <div className="mb-4">
          <TextArea
            name="anythingElse"
            id="anythingElse"
            label="Is there anything else you would like to tell us?"
            value={formdata.anythingElse || ""}
            onChange={handleChange}
          />
        </div>
      </form>

      {/* Save and Continue Button */}
      <SaveAndContinueWithBackButton
        handleComms={handleSubmit}
        isLoading={isLoading}
        action="Apply"
      />
    </div>
  );
};

export default FinancialAid;