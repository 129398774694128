import React, { useState } from 'react';
import { Row, Col, Card, Modal } from 'react-bootstrap';
import { usePaymentSelection } from '../../hooks/usePaymentSelection';

const PaymentOptionInter = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const { submitPaymentSelection } = usePaymentSelection();

    const options = [
        {
            option: "Pay in Full",
            cta: "Choose this option",
            info: "By paying in full, you get a 15% discount Total due 68,000 naira only.",
            bankInfo: "StanbicIBTC Bank",
            accountNum: "0046225284",
            accountName: "Naomi Lucas",
            paymentUrl: "https://paystack.com/buy/tebint"
        },
        {
            option: "Pay In 3 Instalments",
            cta: "Choose this option",
            info: "You can pay in three monthly instalments with a 2.5% admin fee.",
            bankInfo: "StanbicIBTC Bank",
            accountNum: "0046225284",
            accountName: "Naomi Lucas",
            paymentUrl: "https://paystack.com/pay/tebsubscriptionint"
        },
        {
            option: "Apply for Financial Aid",
            cta: "Apply now",
            info: "Every cohort, we give a small percentage of applicants up to 30% off to enable them to attend the bootcamp. There are however, no guarantees. To apply for financial aid, click the button below.", //updated this
            isFinancialAid: true,
            applicationUrl: "/financial-aid-application"
        },
        {
            option: "I'm Not Ready To Make Payment",
            cta: "Choose this option",
            info: "We completely understand if you're not ready to commit to this right now. A member of our admissions team will reach out to you shortly to discuss your participation.", //updated this
            bankInfo: "Your preference has been noted. You will be added to our mailing list and sent periodic reminders.",
            paymentUrl: ""
        }
    ];

    const handleOptionSelect = async (option) => {
        setSelectedOption(option);
        
        if (option.isFinancialAid) {
            // Directly redirect to financial aid application
            window.open(option.applicationUrl, '_blank');
            return;
        }
        
        const result = await submitPaymentSelection(option);
        
        if (result.isConfirmed && option.option !== "I'm Not Ready To Make Payment") {
            setShowModal(true);
        }
    };

    const handleClose = () => setShowModal(false);

    const PaymentDetailsModal = () => (
        <Modal 
            show={showModal} 
            onHide={handleClose} 
            centered 
            size="lg"
            backdrop="static" // Prevents closing when clicking outside
            keyboard={false}  // Prevents closing with keyboard
        >
            <Modal.Header closeButton className="bg-light">
                <Modal.Title className="text-bold" style={{ color: "#212529" }}>
                    Payment Details for {selectedOption?.option}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="px-4">
                {selectedOption && (
                    <div className="payment-details">
                        <div className="payment-info-box p-4 mb-4 bg-light rounded">
                            <h5 className="mb-4 text-dark">Bank Transfer Details</h5>
                            <Row className="mb-3 p-2 border-bottom">
                                <Col xs={5} className="fw-bold text-secondary">Bank Name:</Col>
                                <Col className="text-dark">{selectedOption.bankInfo}</Col>
                            </Row>
                            <Row className="mb-3 p-2 border-bottom">
                                <Col xs={5} className="fw-bold text-secondary">Account Number:</Col>
                                <Col className="text-dark">
                                    <span className="font-monospace">{selectedOption.accountNum}</span>
                                </Col>
                            </Row>
                            <Row className="mb-3 p-2 border-bottom">
                                <Col xs={5} className="fw-bold text-secondary">Account Name:</Col>
                                <Col className="text-dark">{selectedOption.accountName}</Col>
                            </Row>
                        </div>

                        {selectedOption.paymentUrl && (
                            <div className="text-center mt-4">
                                <p className="text-muted mb-3">
                                You can pay using the account details above or click the button below to proceed with your payment through our secure payment portal.
                                </p>
                                <button
                                    variant="primary"
                                    type="button"
                                    size="lg"
                                    className="btn btn-primary"
                                    style={{
                                      backgroundColor: "#ECA239",
                                      color: "black",
                                      border: "1px solid #ECA239",
                                    }}
                                    onClick={() => window.open(selectedOption.paymentUrl, '_blank')}
                                >
                                    Proceed to Payment Portal{' '}
                                    <i className="bi bi-arrow-right-circle ms-2"></i>
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );

    return (
        <div className="col-md-8 slide-col" id="paymentOptionInters">
            <Row xs={1} md={2} className="g-4">
                {options.map((option, idx) => (
                    <Col key={idx}>
                        <Card className="h-100 shadow-sm">
                            <Card.Body className="d-flex flex-column">
                                <Card.Title className="mb-3 fw-bold">{option.option}</Card.Title>
                                <Card.Text className="flex-grow-1">{option.info}</Card.Text>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{
                                        backgroundColor: "#ECA239",
                                        color: "black",
                                        border: "1px solid #ECA239",
                                    }}
                                    onClick={() => handleOptionSelect(option)}
                                >
                                    {option.cta}
                                </button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            <PaymentDetailsModal />
        </div>
    );
};

export default PaymentOptionInter;